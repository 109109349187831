import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { defineStore } from 'pinia';
import { cloneDeep } from '~/assets/helpers/objectHelpers';
export var PagesNames;
(function (PagesNames) {
  PagesNames["Visits"] = "visits";
  PagesNames["Billing"] = "billing";
  PagesNames["MetricsPlans"] = "metrics_plans";
  PagesNames["ReportsJobs"] = "reports_jobs";
  PagesNames["ReportsTemplates"] = "reports_templates";
  PagesNames["ReportsScheduleTemplates"] = "reports_schedule_templates";
  PagesNames["Users"] = "users";
  PagesNames["Routes"] = "routes";
  PagesNames["SFATasks"] = "sfa_tasks";
  PagesNames["SFATemplates"] = "sfa_templates";
  PagesNames["Planogramm"] = "planogramm";
  PagesNames["Stores"] = "stores";
  PagesNames["Retailers"] = "retailers";
  PagesNames["Countries"] = "countries";
  PagesNames["Regions"] = "regions";
  PagesNames["Cities"] = "cities";
  PagesNames["Tasks"] = "tasks";
  PagesNames["PriceStandards"] = "price_standards";
  PagesNames["Posms"] = "posms";
  PagesNames["AssortmentMatrices"] = "assortment_matrices";
  PagesNames["AssortmentProducts"] = "assortment_products";
  PagesNames["AssortmentMatricesType"] = "assortment_matrices_type";
  PagesNames["Segments"] = "segments";
  PagesNames["Periods"] = "periods";
  PagesNames["Metrics"] = "metrics";
  PagesNames["Photos"] = "photos";
  PagesNames["PhotosAppSearch"] = "photos_appsearch";
  PagesNames["Imports"] = "imports";
  PagesNames["Areas"] = "areas";
  PagesNames["AreasStores"] = "areas/stores";
  PagesNames["Products"] = "products";
  PagesNames["SalesLocations"] = "sales-locations";
  PagesNames["MassRecount"] = "mass_recount";
  PagesNames["SceneGroups"] = "scene_groups";
})(PagesNames || (PagesNames = {}));
var VisitBillingFilters = [{
  id: 'country_id',
  show: true
}, {
  id: 'region_id',
  show: true
}, {
  id: 'retailer_id',
  show: true
}, {
  id: 'store_type_id',
  show: true
}, {
  id: 'attributes.store.city.id',
  show: true
},
// { id: 'external_visit_id', show: true },
{
  id: 'photo_id',
  show: true
}];
var defaultPages = {
  [PagesNames.Visits]: {
    columns: [{
      id: 'timestamp',
      locale: 'global.date.date',
      show: true
    }, {
      id: 'visit_type',
      locale: 'visit.visit_type',
      show: true
    }, {
      id: 'user',
      locale: 'users.login',
      show: true
    }, {
      id: 'username',
      locale: 'users.username',
      show: true
    }, {
      id: 'route_number',
      locale: 'routes.route_number',
      show: true
    }, {
      id: 'store',
      locale: 'stores.store',
      show: true
    }, {
      id: 'id',
      locale: 'visit.id',
      show: true
    }, {
      id: 'external_visit_id',
      locale: 'visit.external_id',
      show: true
    }, {
      id: 'photo_cnt',
      locale: 'visit.photo',
      show: true
    }, {
      id: 'osa',
      locale: 'visit.widgets.OSA',
      show: true
    }, {
      id: 'oos',
      locale: 'visit.widgets.OOS',
      show: true
    }, {
      id: 'sos',
      locale: 'visit.widgets.SOS',
      show: true
    }, {
      id: 'posm',
      locale: 'visit.widgets.POSM',
      show: true
    }, {
      id: 'planogram',
      locale: 'visit.widgets.PLAN',
      show: true
    }, {
      id: 'planogram_v2',
      locale: 'visit.widgets.PLAN_V2',
      show: true
    }, {
      id: 'perfect_store',
      locale: 'visit.widgets.PERFECTSTORE',
      show: true
    }, {
      id: 'result_anket',
      locale: 'questionnaire.questionnaire',
      show: false
    }, {
      id: 'user_external_id2',
      locale: 'users.external_id2',
      show: false
    }, {
      id: 'external_store_id',
      locale: 'stores.external_id',
      show: false
    }],
    filters: VisitBillingFilters
  },
  [PagesNames.Billing]: {
    columns: [{
      id: 'timestamp',
      locale: 'global.date.date',
      show: true
    }, {
      id: 'visit_type',
      locale: 'visit.visit_type',
      show: true
    }, {
      id: 'id',
      locale: 'visit.id',
      show: true
    }, {
      id: 'external_visit_id',
      locale: 'visit.external_id',
      show: true
    }, {
      id: 'route_number',
      locale: 'routes.route_number',
      show: true
    }, {
      id: 'store',
      locale: 'stores.store',
      show: true
    }, {
      id: 'user',
      locale: 'users.login',
      show: true
    }, {
      id: 'photo_cnt',
      locale: 'visit.photo',
      show: true
    }, {
      id: 'user_external_id2',
      locale: 'users.external_id2',
      show: false
    }, {
      id: 'external_store_id',
      locale: 'stores.external_id',
      show: false
    }],
    filters: VisitBillingFilters
  },
  [PagesNames.MetricsPlans]: {
    columns: [{
      id: 'active',
      locale: 'global.status.status',
      show: true
    }, {
      id: 'store_name',
      locale: 'planned_metrics.store_name',
      show: true
    }, {
      id: 'store_id',
      locale: 'planned_metrics.store_id',
      show: true
    }, {
      id: 'store_external_id',
      locale: 'planned_metrics.store_external_id',
      show: true
    }, {
      id: 'metric_name',
      locale: 'planned_metrics.metric_name',
      show: true
    }, {
      id: 'metric_id',
      locale: 'planned_metrics.metric_id',
      show: true
    }, {
      id: 'metric_type',
      locale: 'planned_metrics.metric_type',
      show: true
    }, {
      id: 'plan',
      locale: 'planned_metrics.plan',
      show: true
    }]
  },
  [PagesNames.ReportsJobs]: {
    columns: [{
      id: 'status',
      locale: 'report.status.status',
      show: true
    }, {
      id: 'date',
      locale: 'report.upload_date',
      show: true
    }, {
      id: 'name',
      locale: 'report.template_name',
      show: true
    }, {
      id: 'period',
      locale: 'periods.period',
      show: true
    }, {
      id: 'type',
      locale: 'report.report_type',
      show: true
    }]
  },
  [PagesNames.ReportsScheduleTemplates]: {
    columns: [{
      id: 'name',
      locale: 'report.template_name',
      show: true
    }, {
      id: 'status',
      locale: 'report.template_status',
      show: true
    }, {
      id: 'type',
      locale: 'report.report_type',
      show: true
    }, {
      id: 'periodicity',
      locale: 'periods.period',
      show: true
    }]
  },
  [PagesNames.ReportsTemplates]: {
    columns: [{
      id: 'name',
      locale: 'report.template_name',
      show: true
    }, {
      id: 'status',
      locale: 'report.template_status',
      show: true
    }, {
      id: 'type',
      locale: 'report.report_type',
      show: true
    }, {
      id: 'periodicity',
      locale: 'periods.period',
      show: true
    }]
  },
  [PagesNames.Users]: {
    columns: [{
      id: 'status',
      locale: 'users.status',
      show: true
    }, {
      id: 'id',
      locale: 'global.id',
      show: true
    }, {
      id: 'name',
      locale: 'users.login',
      show: true
    }, {
      id: 'username',
      locale: 'users.user',
      show: true
    }, {
      id: 'team',
      locale: 'users.team',
      show: true
    }, {
      id: 'role',
      locale: 'users.role',
      show: true
    }, {
      id: 'external_id',
      locale: 'global.external_id',
      show: false
    }, {
      id: 'external_id2',
      locale: 'global.external_id_2',
      show: true
    }, {
      id: 'project_group_name',
      locale: 'users.project_group',
      show: false
    }, {
      id: 'permissions',
      locale: 'users.permissions',
      show: true
    }, {
      id: 'email',
      locale: 'users.email',
      show: true
    }, {
      id: 'country',
      locale: 'countries.country',
      show: false
    }, {
      id: 'region',
      locale: 'users.region',
      show: false
    }, {
      id: 'brand_owner',
      locale: 'users.brand_owner',
      show: true
    }, {
      id: 'time_zone',
      locale: 'users.timezone',
      show: true
    }]
  },
  [PagesNames.Routes]: {
    columns: [{
      id: 'period',
      locale: 'routes.period',
      show: true
    }, {
      id: 'status',
      locale: 'global.status.status',
      show: true
    }, {
      id: 'user_name',
      locale: 'users.username',
      show: true
    }, {
      id: 'number',
      locale: 'routes.route_number',
      show: true
    }, {
      id: 'external_id',
      locale: 'global.external_id',
      show: true
    }, {
      id: 'type',
      locale: 'routes.route_type',
      show: true
    }, {
      id: 'comment',
      locale: 'routes.route_comment',
      show: true
    }]
  },
  [PagesNames.SFATasks]: {
    columns: [{
      id: 'store',
      locale: 'stores.store',
      show: true
    }, {
      id: 'id',
      locale: 'global.id',
      show: false
    }, {
      id: 'name',
      locale: 'global.name',
      show: true
    }, {
      id: 'description',
      locale: 'sfa_tasks.description',
      show: true
    }, {
      id: 'status',
      locale: 'global.status.status',
      show: true
    }, {
      id: 'planogram',
      locale: 'sfa_tasks.planogramm',
      show: true
    }, {
      id: 'osa_sku',
      locale: 'sfa_tasks.osa_sku',
      show: true
    }, {
      id: 'osa_facing',
      locale: 'sfa_tasks.osa_facing',
      show: true
    }, {
      id: 'osa_shelf',
      locale: 'sfa_tasks.osa_on_shelf',
      show: true
    },
    // { id: 'brand_block', locale: 'metrics.brand_block', show: false },
    {
      id: 'sos',
      locale: 'sfa_tasks.sos',
      show: true
    }, {
      id: 'price_range',
      locale: 'metrics.price_range',
      show: true
    }, {
      id: 'display_width',
      locale: 'metrics.display_width',
      show: false
    }, {
      id: 'iterations',
      locale: 'sfa_tasks.attempts',
      show: true
    }, {
      id: 'date_range',
      locale: 'periods.date_range',
      show: true
    }, {
      id: 'updated_at',
      locale: 'sfa_tasks.change',
      show: true
    }, {
      id: 'assigned_user_name',
      locale: 'sfa_tasks.executor',
      show: true
    }, {
      id: 'author_name',
      locale: 'sfa_tasks.author',
      show: true
    }, {
      id: 'created_at',
      locale: 'equipment_and_posm.created',
      show: true
    }, {
      id: 'result_iteration_comment',
      locale: 'routes.comment',
      show: false
    }, {
      id: 'scene_group_name',
      locale: 'sfa_tasks.scene_group_name',
      show: true
    }, {
      id: 'scene_type_name',
      locale: 'tasks.scene_type_id',
      show: true
    }, {
      id: 'time_start_end',
      locale: 'sfa_tasks.time_start_end',
      show: true
    }, {
      id: 'result_iteration_duration_minutes',
      locale: 'routes.duration',
      show: true
    }]
  },
  [PagesNames.SFATemplates]: {
    columns: [{
      id: 'status',
      locale: 'global.status.status',
      show: true
    }, {
      id: 'template_name',
      locale: 'sfa_tasks.template_name',
      show: true
    }, {
      id: 'created_tasks',
      locale: 'sfa_tasks.created_tasks',
      show: true
    }, {
      id: 'last_launch',
      locale: 'sfa_tasks.last_launch',
      show: true
    }, {
      id: 'creator',
      locale: 'sfa_tasks.creator',
      show: true
    }, {
      id: 'created_at',
      locale: 'sfa_tasks.created',
      show: true
    }]
  },
  [PagesNames.Planogramm]: {
    columns: [{
      id: 'store_name',
      locale: 'sfa_tasks.outlet',
      show: true
    }, {
      id: 'scene_group_name',
      locale: 'planogramm.group_scenes',
      show: true
    }, {
      id: 'eq_num_in_scene_group',
      locale: 'planogramm.scene_order',
      show: true
    }, {
      id: 'scene_type_name',
      locale: 'scene_types.scene_type',
      show: true
    }, {
      id: 'shelf_number',
      locale: 'planogramm.shelf_number',
      show: true
    }, {
      id: 'address',
      locale: 'planogramm.full_shelf_address',
      show: true
    }, {
      id: 'external_product_id',
      locale: 'price_standards.product_external_id',
      show: true
    }, {
      id: 'external_product_name',
      locale: 'planogramm.product_name',
      show: true
    }, {
      id: 'on_shelf_position',
      locale: 'planogramm.number_on_shelf',
      show: true
    }, {
      id: 'faces_width',
      locale: 'planogramm.faces_width',
      show: true
    }, {
      id: 'faces_height',
      locale: 'planogramm.faces_height',
      show: true
    }, {
      id: 'faces_depth',
      locale: 'planogramm.faces_depth',
      show: true
    }, {
      id: 'updated_at',
      locale: 'global.date_update',
      show: true
    }]
  },
  [PagesNames.Stores]: {
    columns: [{
      id: 'id',
      locale: 'planned_metrics.store_id',
      show: true
    }, {
      id: 'external_id',
      locale: 'global.external_id',
      show: true
    }, {
      id: 'external_id2',
      locale: 'global.external_id_2',
      show: true
    }, {
      id: 'store_type_name',
      locale: 'stores.store_type',
      show: true
    }, {
      id: 'name',
      locale: 'stores.name',
      show: true
    }, {
      id: 'address',
      locale: 'global.address',
      show: true
    }, {
      id: 'city_name',
      locale: 'city.city',
      show: true
    }, {
      id: 'retailer_name',
      locale: 'retailer.retailer',
      show: true
    }, {
      id: 'region_name',
      locale: 'region.region',
      show: true
    }, {
      id: 'country_name',
      locale: 'countries.country',
      show: true
    }, {
      id: 'active_matrices_count',
      locale: 'stores.matrices',
      show: true
    }, {
      id: 'lat',
      locale: 'stores.latitude',
      show: true
    }, {
      id: 'lon',
      locale: 'stores.longitude',
      show: true
    }, {
      id: 'segment_name',
      locale: 'store_segments.segment',
      show: true
    }, {
      id: 'territory',
      locale: 'stores.territory',
      show: true
    }, {
      id: 'territory2_name',
      locale: 'stores.territory_2',
      show: true
    }, {
      id: 'category',
      locale: 'visit.category',
      show: true
    }, {
      id: 'type',
      locale: 'stores.subtype',
      show: true
    }]
  },
  [PagesNames.Retailers]: {
    columns: [{
      id: 'name',
      locale: 'global.name',
      show: true
    }, {
      id: 'external_id',
      locale: 'global.external_id',
      show: true
    }]
  },
  [PagesNames.Countries]: {
    columns: [{
      id: 'name',
      locale: 'global.name',
      show: true
    }]
  },
  [PagesNames.Regions]: {
    columns: [
    // { id: 'id', locale: 'global.id', show: false },
    {
      id: 'name',
      locale: 'region.region',
      show: true
    }, {
      id: 'country_name',
      locale: 'countries.country',
      show: true
    }]
  },
  [PagesNames.Cities]: {
    columns: [
    // { id: 'city_id', locale: 'global.id', show: false },
    {
      id: 'name',
      locale: 'city.city',
      show: true
    }, {
      id: 'country_name',
      locale: 'countries.country',
      show: true
    }, {
      id: 'region_name',
      locale: 'region.region',
      show: true
    }]
  },
  [PagesNames.Tasks]: {
    columns: [{
      id: 'is_disabled',
      locale: 'global.status.status',
      show: true
    }, {
      id: 'name',
      locale: 'tasks.name',
      show: true
    }, {
      id: 'is_required',
      locale: 'tasks.required',
      show: true
    }, {
      id: 'store_segments',
      locale: 'tasks.store_segment_ids',
      show: true
    }, {
      id: 'kpis',
      locale: 'tasks.kpis',
      show: true
    }, {
      id: 'questions',
      locale: 'tasks.questions',
      show: true
    }]
  },
  [PagesNames.PriceStandards]: {
    columns: [{
      id: 'id',
      locale: 'global.id',
      show: true
    },
    // { id: 'status', locale: 'report.status.status', show: true },
    {
      id: 'product_id',
      locale: 'price_standards.product_id',
      show: true
    }, {
      id: 'product_name',
      locale: 'price_standards.product_name',
      show: true
    }, {
      id: 'miniature_url',
      locale: 'tasks.image',
      show: true
    }, {
      id: 'product_external_id',
      locale: 'price_standards.product_external_id',
      show: true
    }, {
      id: 'type',
      locale: 'price_standards.range_type',
      show: true
    }, {
      id: 'min_range',
      locale: 'price_standards.min_range',
      show: true
    }, {
      id: 'max_range',
      locale: 'price_standards.max_range',
      show: true
    }, {
      id: 'price_type',
      locale: 'price_standards.price_type',
      show: true
    }, {
      id: 'store_name',
      locale: 'stores.store',
      show: true
    }, {
      id: 'country_name',
      locale: 'countries.country',
      show: true
    }, {
      id: 'region_name',
      locale: 'region.region',
      show: true
    }, {
      id: 'retailer_name',
      locale: 'retailer.retailer',
      show: true
    }, {
      id: 'segment_name',
      locale: 'store_segments.segment',
      show: true
    }, {
      id: 'period',
      locale: 'periods.period',
      show: true
    }]
  },
  [PagesNames.Posms]: {
    columns: [{
      id: 'is_active',
      locale: 'global.status.status',
      show: true
    }, {
      id: 'miniature_url',
      locale: 'tasks.image',
      show: true
    }, {
      id: 'pk',
      locale: 'global.id',
      show: false
    }, {
      id: 'name',
      locale: 'global.name',
      show: true
    }, {
      id: 'external_id',
      locale: 'global.external_id',
      show: true
    }, {
      id: 'code',
      locale: 'klass.code',
      show: true
    }, {
      id: 'category_name',
      locale: 'equipment_and_posm.category',
      show: true
    }, {
      id: 'brand_name',
      locale: 'visit.brand_column',
      show: true
    }, {
      id: 'subbrand_name',
      locale: 'equipment_and_posm.subbrand',
      show: true
    }, {
      id: 'brand_owner_name',
      locale: 'visit.brand_owner',
      show: true
    }]
  },
  [PagesNames.AssortmentMatrices]: {
    columns: [{
      id: 'id',
      locale: 'global.id',
      show: true
    }, {
      id: 'name',
      locale: 'global.name',
      show: true
    }, {
      id: 'external_id',
      locale: 'global.external_id',
      show: true
    }, {
      id: 'entry_date',
      locale: 'global.date_update',
      show: true
    }, {
      id: 'dt_start',
      locale: 'periods.dt_start',
      show: true
    }, {
      id: 'dt_end',
      locale: 'periods.dt_end',
      show: true
    }, {
      id: 'type_name',
      locale: 'metrics.matrix_type',
      show: true
    }, {
      id: 'store_name',
      locale: 'routes.store_name',
      show: true
    }, {
      id: 'external_store_id',
      locale: 'stores.external_id_short',
      show: true
    }, {
      id: 'store_id',
      locale: 'planned_metrics.store_id',
      show: true
    }, {
      id: 'assortment',
      locale: 'products.products',
      show: true
    }]
  },
  [PagesNames.AssortmentProducts]: {
    columns: [{
      id: 'product_id',
      locale: 'price_standards.product_id',
      show: true
    }, {
      id: 'product_name',
      locale: 'visit.product',
      show: true
    }, {
      id: 'external_product_id',
      locale: 'global.external_id',
      show: true
    }, {
      id: 'facing',
      locale: 'assortment.facing',
      show: true
    }, {
      id: 'shelf_num',
      locale: 'planogramm.shelf_number',
      show: true
    }, {
      id: 'assortment_group_name',
      locale: 'assortment.group',
      show: true
    }, {
      id: 'not_use',
      locale: 'assortment.not_use',
      show: true
    }]
  },
  [PagesNames.AssortmentMatricesType]: {
    columns: [{
      id: 'id',
      locale: 'global.id',
      show: true
    }, {
      id: 'code',
      locale: 'assortment_type.code',
      show: true
    }, {
      id: 'name',
      locale: 'global.name',
      show: true
    }, {
      id: 'local_name',
      locale: 'global.local_name',
      show: true
    }, {
      id: 'plan_count',
      locale: 'stores.matrix_count',
      show: true
    }, {
      id: 'calculation_type',
      locale: 'assortment_type.calculation_type',
      show: true
    }, {
      id: 'formula',
      locale: 'assortment_type.calculation_formula',
      show: true
    }]
  },
  [PagesNames.Segments]: {
    columns: [{
      id: 'is_disabled',
      locale: 'global.status.status',
      show: true
    }, {
      id: 'name',
      locale: 'global.name',
      show: true
    }, {
      id: 'external_id',
      locale: 'global.external_id',
      show: true
    }]
  },
  [PagesNames.Periods]: {
    columns: [{
      id: 'is_disabled',
      locale: 'global.status.status',
      show: true
    }, {
      id: 'id',
      locale: 'global.id',
      show: true
    }, {
      id: 'name',
      locale: 'global.name',
      show: true
    }, {
      id: 'dt_start',
      locale: 'periods.dt_start',
      show: true
    }, {
      id: 'dt_end',
      locale: 'periods.dt_end',
      show: true
    }]
  },
  [PagesNames.Metrics]: {
    columns: [{
      id: 'is_disabled',
      locale: 'global.status.status',
      show: true
    }, {
      id: 'pk',
      locale: 'global.id',
      show: true
    }, {
      id: 'external_id',
      locale: 'global.external_id',
      show: true
    }, {
      id: 'name',
      locale: 'global.name',
      show: true
    }, {
      id: 'type',
      locale: 'metrics.type',
      show: true
    }, {
      id: 'params',
      locale: 'metrics.params',
      show: true
    }]
  },
  [PagesNames.Photos]: {
    filters: [{
      id: 'dt_create',
      show: true
    }, {
      id: 'user_id',
      show: true
    }, {
      id: 'area_id',
      show: true
    }, {
      id: 'country_id',
      show: true
    }, {
      id: 'region_id',
      show: true
    }, {
      id: 'retailer_id',
      show: true
    }, {
      id: 'store_type_id',
      show: true
    }, {
      id: 'store_id',
      show: true
    }, {
      id: 'scene_type_id',
      show: true
    }, {
      id: 'photo_error',
      show: true
    }, {
      id: 'product_category_id',
      show: true
    }, {
      id: 'product_id',
      show: true
    }, {
      id: 'brand_id',
      show: true
    }, {
      id: 'brand_owner_id',
      show: true
    }, {
      id: 'price_type',
      show: true
    }, {
      id: 'price',
      show: true
    }]
  },
  [PagesNames.PhotosAppSearch]: {
    filters: [{
      id: 'is_corrected',
      show: true
    }, {
      id: 'created_at',
      show: true
    }, {
      id: 'user_name',
      show: true
    }, {
      id: 'territory_name',
      show: true
    }, {
      id: 'country_name',
      show: true
    }, {
      id: 'region_name',
      show: true
    }, {
      id: 'city_name',
      show: true
    }, {
      id: 'retailer_name',
      show: true
    }, {
      id: 'store_type_name',
      show: true
    }, {
      id: 'store_name',
      show: true
    }, {
      id: 'scene_type_name',
      show: true
    }, {
      id: 'photo_error',
      show: true
    }, {
      id: 'product_category_name',
      show: true
    }, {
      id: 'brand_name',
      show: true
    }, {
      id: 'product_name',
      show: true
    }, {
      id: 'brand_owner_name',
      show: true
    }, {
      id: 'visit_id',
      show: true
    }, {
      id: 'external_visit_id',
      show: true
    }, {
      id: 'visit_status',
      show: true
    }, {
      id: 'task_name',
      show: true
    }, {
      id: 'task_id',
      show: true
    }, {
      id: 'price_type',
      show: true
    }, {
      id: 'price',
      show: true
    }]
  },
  [PagesNames.Imports]: {
    columns: [{
      id: 'status',
      locale: 'global.status.status',
      show: true
    }, {
      id: 'start_at',
      locale: 'imports.start_at',
      show: true
    }, {
      id: 'finish_at',
      locale: 'imports.finish_at',
      show: true
    }, {
      id: 'user_login',
      locale: 'users.user',
      show: true
    }, {
      id: 'name',
      locale: 'imports.import_file',
      show: true
    }, {
      id: 'src_s3_size',
      locale: 'imports.size',
      show: true
    }, {
      id: 'errors',
      locale: 'imports.import_errors',
      show: true
    }, {
      id: 'logs',
      locale: 'imports.logs',
      show: true
    }, {
      id: 'type',
      locale: 'imports.type',
      show: true
    }]
  },
  [PagesNames.Areas]: {
    columns: [{
      id: 'name',
      locale: 'global.name',
      show: true
    }, {
      id: 'users',
      locale: 'areas.user_logins',
      show: true
    }, {
      id: 'stores_count',
      locale: 'areas.count_tt',
      show: true
    }]
  },
  [PagesNames.AreasStores]: {
    columns: [{
      id: 'external_id',
      locale: 'global.external_id',
      show: true
    }, {
      id: 'name',
      locale: 'global.name',
      show: true
    }, {
      id: 'address',
      locale: 'global.address',
      show: true
    }]
  },
  [PagesNames.SalesLocations]: {
    columns: [{
      id: 'is_active',
      locale: 'global.status.status',
      show: true
    }, {
      id: 'id',
      locale: 'global.id',
      show: true
    }, {
      id: 'name',
      locale: 'global.name',
      show: true
    }, {
      id: 'scene_group_id',
      locale: 'sfa_tasks.scene_group_name',
      show: true
    }, {
      id: 'short_name',
      locale: 'global.short_name',
      show: true
    }, {
      id: 'local_name',
      locale: 'global.local_name',
      show: true
    },
    // { id: 'facing_only', locale: 'sales_locations.cropping', show: true },
    {
      id: 'color',
      locale: 'sales_locations.color',
      show: true
    }]
  },
  [PagesNames.MassRecount]: {
    columns: [{
      id: 'status',
      locale: 'global.status.status',
      show: true
    }, {
      id: 'metrics_only',
      locale: 'mass_recalculation.recalc_type',
      show: true
    }, {
      id: 'option',
      locale: 'mass_recalculation.recalc_option',
      show: true
    }, {
      id: 'start_at',
      locale: 'imports.start_at',
      show: true
    }, {
      id: 'finish_at',
      locale: 'imports.finish_at',
      show: true
    }, {
      id: 'user',
      locale: 'sfa_tasks.initiator',
      show: true
    }, {
      id: 'photos_count',
      locale: 'visit.photo',
      show: true
    }, {
      id: 'visits_count',
      locale: 'visit.visits',
      show: true
    }]
  },
  [PagesNames.SceneGroups]: {
    columns: [{
      id: 'id',
      locale: 'global.id',
      show: true
    }, {
      id: 'name',
      locale: 'global.name',
      show: true
    }, {
      id: 'local_name',
      locale: 'global.local_name',
      show: true
    }]
  }
};
var usePagesStore = defineStore('pagesStore', () => {
  var pages = ref(Object.entries(defaultPages).reduce((res, _ref) => {
    var [pageKey, pageValue] = _ref;
    res[pageKey] = {
      columns: cloneDeep(pageValue.columns || []),
      filters: cloneDeep(pageValue.filters || [])
    };
    return res;
  }, {}));
  function updateColumns(page, columns) {
    pages.value[page].columns = columns;
  }
  function resetColumns(page) {
    pages.value[page].columns = cloneDeep(defaultPages[page].columns);
  }
  function updateFilters(page, filters) {
    pages.value[page].filters = filters;
  }
  function resetFilters(page) {
    pages.value[page].filters = cloneDeep(defaultPages[page].filters);
  }
  return {
    pages,
    updateColumns,
    resetColumns,
    updateFilters,
    resetFilters
  };
}, {
  // @ts-ignore
  persistedState: {
    includePaths: ['pages'],
    migrate(value) {
      Object.entries(defaultPages).forEach(_ref2 => {
        var _val$columns;
        var [key, val] = _ref2;
        var storePage = value.pages[key];
        var initColumns = val.columns || [];

        // Init columns, if not exist
        if (!(storePage !== null && storePage !== void 0 && storePage.columns.length)) {
          value.pages[key].columns = cloneDeep(initColumns);
        } else if (((_val$columns = val.columns) === null || _val$columns === void 0 ? void 0 : _val$columns.length) !== storePage.columns.length) {
          // Save settings for columns

          var savedSettings = storePage.columns.reduce((res, column) => {
            res[column.id] = column;
            return res;
          }, {});
          storePage.columns = cloneDeep(initColumns).map(column => savedSettings[column.id] || column);
        }
        var initFilters = val.filters || [];

        // Init filters, if not exist
        if (!(storePage !== null && storePage !== void 0 && storePage.filters.length)) {
          value.pages[key].filters = cloneDeep(initFilters);
        } else {
          var _val$filters;
          // Save settings for filters
          var _savedSettings = storePage.filters.reduce((res, filter) => {
            res[filter.id] = filter;
            return res;
          }, {});
          if (((_val$filters = val.filters) === null || _val$filters === void 0 ? void 0 : _val$filters.length) !== storePage.filters.length) {
            storePage.filters = cloneDeep(initFilters).map(filter => _savedSettings[filter.id] || filter);
          } else if (initFilters.some(f => !_savedSettings[f.id])) {
            value.pages[key].filters = cloneDeep(initFilters);
          }
        }
      });
      return value;
    }
  }
});
export default usePagesStore;